<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/questionnaire">Transferees</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Contact Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Transferors</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Property Description</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/part-e-terms">Terms</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Gross Purchase Price</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Additional Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Principal Residence Info</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Tax Calculation</a>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/first-time-home-buyer">First Time Home Buyers</router-link>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/edit-application/certification">Certification</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Attachments</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation</a>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Presentation Copy</a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-7">

              <h2 class="mb-3">First Time Home Buyers' Declaration</h2>
              <div class="alert alert-neutral">
                Complete this section only if you are applying for the first time home buyers’ exemption. Before completing, make sure you read and understand the qualifications for the exemption and the first year requirements, as explained in the <a href="javascript:void(0)"><u>Guide to the First Time Home Buyers’ Program</u> <svg class="icon icon-External-link"><use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg></a>.
              </div>

              <p class="lead"><strong>Penalty for False Declaration</strong> – if you make a false declaration, you will be charged an amount equal to double the tax (the tax you owe plus a penalty equal to the exemption you claimed).</p>

              <a class="collapse-toggle" href="#principalResidenceHelp" data-toggle="collapse" aria-expanded="false" aria-controls="principalResidenceHelp">
                <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                <svg class="icon icon-close-circle"><use xlink:href="/icons/symbol-defs.svg#icon-close-circle"></use></svg>
                What is a principal residence?
              </a>

              <div class="collapse" id="principalResidenceHelp">
                <div class="card card-body bg-light mt-1">
                  A principal residence is the usual place that a person makes their home. If a person owns more than one home, they can't designate which one is their principal residence. Their principal residence is where they live and conduct their daily affairs, like paying bills and receiving mail, and it's generally the residence used in government records for things like income tax, Medical Services Plan, driver's licence and vehicle registration.
                </div>
              </div>

              <!-- Transferee 1 -->
              <div class="my-4 p-2 p-md-4 bg-medium">
                <h3>Transferee 1 Name</h3>

                <label class="mt-1">1. Have you owned an interest in a principal residence (where you lived) anywhere in the world at any time?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="ownedAnInterest" name="ownedAnInterest" class="custom-control-input">
                    <label class="custom-control-label" for="ownedAnInterest">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="ownedAnInterest2" name="ownedAnInterest" class="custom-control-input">
                    <label class="custom-control-label" for="ownedAnInterest2">No</label>
                  </div>
                </div>

                <label class="mt-1">2. Have you ever received a BC first time home buyers’ exemption or refund?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="everReceived" name="everReceived" class="custom-control-input">
                    <label class="custom-control-label" for="everReceived">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="everReceived2" name="everReceived" class="custom-control-input">
                    <label class="custom-control-label" for="everReceived2">No</label>
                  </div>
                </div>

                <label class="mt-1">3. On the date of registration, are you a Canadian citizen or a permanent resident as defined in the <a href="javascript:void(0)"><u>Immigration and Refugee Protection Act</u> <svg class="icon icon-External-link"><use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg></a> (Canada)?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="canadianCitizen" name="canadianCitizen" class="custom-control-input">
                    <label class="custom-control-label" for="canadianCitizen">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="canadianCitizen2" name="canadianCitizen" class="custom-control-input">
                    <label class="custom-control-label" for="canadianCitizen2">No</label>
                  </div>
                </div>

                <label class="mt-1">4. Have you continuously resided in BC for at least one year immediately prior to the registration date OR filed two income tax returns as a BC resident during the six years before the date of registration?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="residedOneYear" name="residedOneYear" class="custom-control-input">
                    <label class="custom-control-label" for="residedOneYear">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="residedOneYear2" name="residedOneYear" class="custom-control-input">
                    <label class="custom-control-label" for="residedOneYear2">No</label>
                  </div>
                </div>
              </div>

              <!-- Transferee 2 -->
              <div class="mb-4 p-2 p-md-4 bg-medium">
                <h3>Transferee 2 Name</h3>

                <label class="mt-1">1. Have you owned an interest in a principal residence (where you lived) anywhere in the world at any time?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="ownedAnInterest2" name="ownedAnInterest2" class="custom-control-input">
                    <label class="custom-control-label" for="ownedAnInterest2">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="ownedAnInterest22" name="ownedAnInterest2" class="custom-control-input">
                    <label class="custom-control-label" for="ownedAnInterest22">No</label>
                  </div>
                </div>

                <label class="mt-1">2. Have you ever received a BC first time home buyers’ exemption or refund?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="everReceived2" name="everReceived2" class="custom-control-input">
                    <label class="custom-control-label" for="everReceived2">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="everReceived22" name="everReceived2" class="custom-control-input">
                    <label class="custom-control-label" for="everReceived22">No</label>
                  </div>
                </div>

                <label class="mt-1">3. On the date of registration, are you a Canadian citizen or a permanent resident as defined in the <a href="javascript:void(0)"><u>Immigration and Refugee Protection Act</u> <svg class="icon icon-External-link"><use xlink:href="/icons/symbol-defs.svg#icon-External-link"></use></svg></a> (Canada)?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="canadianCitizen2" name="canadianCitizen2" class="custom-control-input">
                    <label class="custom-control-label" for="canadianCitizen2">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="canadianCitizen22" name="canadianCitizen2" class="custom-control-input">
                    <label class="custom-control-label" for="canadianCitizen22">No</label>
                  </div>
                </div>

                <label class="mt-1">4. Have you continuously resided in BC for at least one year immediately prior to the registration date OR filed two income tax returns as a BC resident during the six years before the date of registration?</label>
                <div class="form-group mt-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="residedOneYear2" name="residedOneYear2" class="custom-control-input">
                    <label class="custom-control-label" for="residedOneYear2">Yes</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="residedOneYear22" name="residedOneYear2" class="custom-control-input">
                    <label class="custom-control-label" for="residedOneYear22">No</label>
                  </div>
                </div>
              </div>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

